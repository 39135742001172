export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'Son of a Pitch Football Company, LLC',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome',
  'client_theme.ThemeSidebar.mission':
    'Welcome! "Son of a Pitch Football Company" is a homage to a life shaped by the beautiful game. It is a reflection of a journey that started with a simple choice: to play, to learn, and to grow on the soccer pitch. It is a commitment to fostering that same love, that same journey, in others. The company stands as a testament to the belief that football is more than a game; it is a way of life, a teacher, and a guide, and in its name, that philosophy is both celebrated and perpetuated.',
  'client_theme.ThemeSidebar.learn_more': 'Learn More', // leave a space here so we don't display this for main instance.
};
